import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import Button from "../../../../Buttons/button";
import TextField from "../../../../TextField/TextField";
import {getInitializeServicesData, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";

import DragDrop from "../../../../DragDrop/DragDrop";
import MonthPicker from "../../../../MonthPicker/MonthPicker";
import calendarIcon from "../../../../../assets/images/circum_calendar.png";
import chevronIcon from "../../../../../assets/images/chevronIcon.png";
import { addMonths, subMonths } from "date-fns";

function UserUsegesData({formValid, id, file, setFile, selectedDate, setSelectedDate}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData} = State
  const setupServices = SetServicesData?.setup_services;
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [serviceName, setServiceName] = useState("");

  useEffect(()=>{
    dispatch(getInitializeServicesData())
  },[])

  useEffect(()=>{
    setFile(setupServices.useges_file_name)
    if(setupServices.billing_date){
      setSelectedDate(setupServices.billing_date)
    }
  },[id, setupServices])

  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLSX"];
  
  const element = document.getElementById('calendarWrap');

  useEffect(() => {
    updateSelectedDate(selectedDate);
  }, [selectedDate]);

  const updateSelectedDate = (date) => {
    const currentDate = date ? new Date(date) : new Date();
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    });
    const formattedDate = formatter.format(currentDate);

    const [month, year] = formattedDate.split(" ");
    const formattedDateString = `${month}, ${year}`;

    const calendarVal = document.getElementById("calendarVal");
    if (calendarVal) {
      calendarVal.textContent = formattedDateString;
    }
  };

  const incrementMonth = (prevDate) => {
    setSelectedDate((prevDate) => {
      const currentDate = prevDate ? prevDate : new Date();
      return addMonths(currentDate, 1);
    });
  };

  const decrementMonth = (prevDate) => {
    setSelectedDate((prevDate) => {
      const currentDate = prevDate ? prevDate : new Date();
      return subMonths(currentDate, 1);
    });
  };

  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
      <div className="flex flex-start flex-col text-left">
        <div className="text-primary !text-[24px] !leading-[36px]">User usage data</div>
        <div className="flex flex-start step_heading">Add the most recent service usage data for this service. Thryft will generate any new reports using the latest data provided.</div>
      </div>

      <div className="flex flex-col MonthPickerWrap onlyMonth">
        <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
          Select data month
          {
            !formValid &&
            (
              <span>
                <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
              </span>
            )
          }
        </label>
        <div className="calendarWrap" id="calendarWrap" >
          <img src={calendarIcon} alt="icon" className="calendarIcon" />
          <MonthPicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} updateSelectedDate={updateSelectedDate}/>
          <div id="calendarVal" className="calendarVal"></div>
          <div className="calChev">
            <div className="upBtn" onClick={()=> {incrementMonth(selectedDate)}}>
              <img src={chevronIcon} alt="icon"  />
            </div>
            <div className="downBtn" onClick={()=> {decrementMonth(selectedDate)}}>
              <img src={chevronIcon} alt="icon"  />
            </div>
          </div>
        </div>
      </div>

      <DragDrop setFile={setFile}/>
      

    </div>
  );
}

export default UserUsegesData;