import { useState, useEffect } from "react";
import BasicModal from "../../../components/models/models";
import Table from "../../../components/Table/table";
import Header from "./components/header";
import PillCards from "./components/pill-cards";
import expenseIcon from "../../../assets/icons/expense_icon.svg";
import { useDispatch, useSelector } from "react-redux";

import { heading, tableData } from "../../../services/expensesTableData";
import { getUserExpenses } from "../../../redux/slices/personalDashboardSlice";


const initialState = {
  category: false,
  instances: false,
  cost: false,
};

function ExpensesModule() {
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("service_name");
  const [orderType, setOrderType] = useState("asc");
  const [state, setState] = useState(0);
  const [id, setId] = useState(null);

  const [data, setData] = useState([...tableData]);

  const transformedData = transformResponse(tableData);

  function transformResponse(response = []) {
    return response?.map((item) => {
      return {
        id: item.id,
        category: item.category,
        instances: item.instances,
        cost: item.cost,
      };
    });
  }

  useEffect(() => {
    setData([...tableData]);
  }, []);

  useEffect(() => {
    dispatch(getUserExpenses());
  }, [dispatch]);

  const handleExpensesCategory = (orderType, set) => {
    setBtns({
      ...btns,
      type: true,
      [active !== "category" && active]: false,
    });
    setActive("category");
    const sort = [...data].sort((a, b) => {
      const serviceA = a.category || "";
      const serviceB = b.category || "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleExpensesInstances = (orderType, set) => {
    setBtns({
      ...btns,
      type: true,
      [active !== "instances" && active]: false,
    });
    setActive("instances");
    const sort = [...data].sort((a, b) => {
      const serviceA = a.instances || 0;
      const serviceB = b.instances || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });
    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleExpensesCost = (orderType, set) => {
    setBtns({
      ...btns,
      type: true,
      [active !== "cost" && active]: false,
    });
    setActive("cost");
    const sort = [...data].sort((a, b) => {
      const serviceA = a.cost || 0;
      const serviceB = b.cost || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });
    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  return (
    <div className="right_panel flex flex-col w-[100%] gap-[32px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      <Header
        title={"Your Expenses (Jan)"}
        subHeading={"Updated Jan 11 2023"}
      />

      <PillCards totalMonthlyCost={2000} />

      <div className="card-section">
        <div className="cards_wrap double_space bg-[#363D50] w-[100%] rounded-[24px] overflow-y-scroll scrollbar-hide max-h-[470px] md:max-h-[670px]">
          <div className="p-6 pb-2 flex items-center gap-4">
            <div className="p-2 rounded-2xl bg-[#242B3E]">
              <img
                src={expenseIcon}
                alt="expense icon"
                className="w-[30px]"
                style={{ filter: "invert(1)" }}
              />
            </div>
            <div>
              <p className="text-base font-bold text-white">Expenses</p>
              <p className="text-secondary font-light">
                Expenses reported by your company
              </p>
            </div>
          </div>
          <Table
            headings={heading}
            tableData={data}
            setState={setState}
            setId={setId}
            setPopup={setPopup}
            table={"expenses"}
            handleExpensesCategory={handleExpensesCategory}
            handleExpensesInstances={handleExpensesInstances}
            handleExpensesCost={handleExpensesCost}
            orderType={orderType}
          />
        </div>
      </div>

      {setPopup && (
        <BasicModal
          state={state}
          id={id}
          setId={setId}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          tablename="personaldashborad"
        />
      )}
    </div>
  );
}

export default ExpensesModule;
