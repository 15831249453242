import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";

export const useFormattedAmount = () => {
  const { userDetails } = useSelector(selectUser);

  const formatFunction = (amount) => {
    if (amount === null) return "";
    const currencySymbol = userDetails?.currency_symbol || "";
    const formattedAmount = amount
      ? (+parseFloat(amount).toFixed(2)).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      : "0";

    return `${currencySymbol}${formattedAmount}`;
  };
  return formatFunction;
};
